<template>
  <div class="main" data-title="营销分析" v-title>
    <van-cell-group title=" ">
      <van-panel title="各时间段来店客户情况">
        <div class="chart-content">
          <ve-bar
            :data="chartData.timeInterval"
            :legend-visible="false"
            :settings="chartSettings"
          ></ve-bar>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="各时间段来店客户情况比">
        <div class="chart-content">
          <ve-pie
            :data="chartData.timeInterval"
            :legend-visible="false"
          ></ve-pie>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="客户获知信息渠道来源数">
        <div class="chart-content">
          <ve-bar
            :data="chartData.influence"
            :legend-visible="false"
            :settings="chartSettings"
          ></ve-bar>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="客户获知信息渠道来源数比">
        <div class="chart-content">
          <ve-pie :data="chartData.influence" :legend-visible="false"></ve-pie>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="客户关注车型数">
        <div class="chart-content">
          <ve-bar
            :data="chartData.demandCarType"
            :legend-visible="false"
            :settings="chartSettings"
          ></ve-bar>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="客户关注车型数比">
        <div class="chart-content">
          <ve-pie
            :data="chartData.demandCarType"
            :legend-visible="false"
          ></ve-pie>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="客户意向级别数">
        <div class="chart-content">
          <ve-bar
            :data="chartData.customerLevel"
            :legend-visible="false"
            :settings="chartSettings"
          ></ve-bar>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="客户意向级别数比">
        <div class="chart-content">
          <ve-pie
            :data="chartData.customerLevel"
            :legend-visible="false"
          ></ve-pie>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="旧车评估数">
        <div class="chart-content">
          <ve-bar
            :data="chartData.usedEvaluate"
            :legend-visible="false"
            :settings="chartSettings"
          ></ve-bar>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="各区域客户来源数">
        <div class="chart-content">
          <ve-bar
            :data="chartData.customerArea"
            :legend-visible="false"
            :settings="chartSettings"
          ></ve-bar>
        </div>
      </van-panel>
    </van-cell-group>

    <van-cell-group title=" ">
      <van-panel title="各区域客户来源数比">
        <div class="chart-content">
          <ve-pie
            :data="chartData.customerArea"
            :legend-visible="false"
          ></ve-pie>
        </div>
      </van-panel>
    </van-cell-group>
    <chart-search></chart-search>
  </div>
</template>
<script>
import { monthFormat } from '@/unit/unit.js'
import VeBar from 'v-charts/lib/bar.common'
import VePie from 'v-charts/lib/pie.common'
import ChartSearch from '@/components/chart/ChartSearch.vue'
export default {
  components: {
    ChartSearch,
    // SelectDep,
    VeBar,
    VePie,
  },
  data() {
    this.chartSettings = {
      labelMap: {
        num: '数量',
      },
    }
    return {
      key: [monthFormat(), monthFormat()],
      dept_ids: [],
      listData: [],
      isData: false,

      chartData: {
        timeInterval: {
          columns: ['type', 'num'],
          rows: [],
        },
        influence: {
          columns: ['type', 'num'],
          rows: [],
        },
        customerArea: {
          columns: ['type', 'num'],
          rows: [],
        },
        demandCarType: {
          columns: ['type', 'num'],
          rows: [],
        },
        customerLevel: {
          columns: ['type', 'num'],
          rows: [],
        },
        usedEvaluate: {
          columns: ['type', 'num'],
          rows: [{ type: '旧车评估数', num: 0 }],
        },
      },
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    setDeps(arr) {
      this.dept_ids = arr
      this.getListData()
    },
    getNewData() {
      this.getListData()
    },
    getListData() {
      this.chartData.timeInterval.rows = []
      this.chartData.influence.rows = []
      this.chartData.customerArea.rows = []
      this.chartData.demandCarType.rows = []
      this.chartData.customerLevel.rows = []
      this.chartData.usedEvaluate.rows.num = 0

      this.$axios
        .post('/statistic/data2', {
          begin_month: this.key[0],
          end_month: this.key[1],
          dept_ids: this.dept_ids,
        })
        .then((res) => {
          if (res.data.code == 200 && res.data.data.list.length) {
            this.isData = true
            //设置各时间来店情况
            this.initChartData(res, 'timeInterval')
            this.initChartData(res, 'influence')
            this.initChartData(res, 'customerArea')
            this.initChartData(res, 'demandCarType')
            this.initChartData(res, 'customerLevel')

            res.data.data.list.map((item) => {
              this.chartData.usedEvaluate.rows[0].num += item.usedEvaluate
            })
          } else {
            this.isData = false
          }
        })
    },
    initChartData(res, str) {
      let _this = this
      res.data.data.list.map((item, index) => {
        Object.keys(item[str]).map((k, v) => {
          if (index == 0) {
            _this.chartData[str].rows.push({
              type: this.getCfgData(k),
              num: item[str][k],
            })
          } else {
            let i = parseInt(_this.chartData[str].rows[v].num)
            _this.chartData[str].rows[v].num = i + parseInt(item[str][k])
          }
        })
      })
    },

    getCfgData(val) {
      if (val == '') {
        return '-'
      }
      let i = 0

      if (typeof val == 'string') {
        i = parseInt(val)
      } else {
        i = val
      }

      return this.$store.state.cfgData[i][0]
    },
  },
}
</script>
<style lang="less" scoped>
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  .table {
    border-collapse: collapse;
    th,
    td {
      background: #f5f7fa;
      color: rgba(0, 0, 0, 0.85);
      border: 1px solid #ebeef5;
      padding: 7px 10px;
      font-size: 12px;
      word-break: keep-all;
      white-space: nowrap;
      text-align: center;
      &:first-child {
        border-left: none;
      }
    }

    td {
      background: #fff;
      color: #606266;
    }
  }
}
.chart-content {
  padding: 10px 20px;
}
</style>